import React from 'react';
import { Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const template = () => {
  return (
    <>
      <Typography variant="h1" component="h1">
        Patient Centricity through Telemedicine Amidst Pandemic: A Clinical Trial for Treatment of COVID-19 in India, across 20+ sites, supported in 10+ languages
      </Typography>
      <a href="https://www.bioconbiologics.com/" target={'_blank'} class="linkcolor">
      Biocon Biologics
      </a>
    </>
  );
}

export default template;