import React from 'react';
import { Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const template = () => {
  return (
    <>
      <Typography variant="h1" component="h1">
      Onchocerciasis Study in Democratic Republic of Congo and Côte d'Ivoire: Technology and Determination to Reach Underserved Populations
      </Typography>
      <Typography variant="body2" color={blueGrey[400]}>
      <a href="https://www.medicinesdevelopment.com/" target={'_blank'} class="linkcolor">Medicines Development for Global Health</a>
      </Typography>
    </>
  );
}

export default template;