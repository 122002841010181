import React from 'react';
import { Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const template = () => {
  return (
    <>
      <Typography variant="h1" component="h1">
      Type 2 Diabetes, Chronic Hepatitis B and Bronchial Asthma studies: One Technology Platform across multiple sites in India
      </Typography>
      <a href="https://www.narayanahealth.org/" target={'_blank'} class="linkcolor">
      Narayana Health
      </a>
    </>
  );
}

export default template;