import React from 'react';
import { Stack, Typography, Box, Link, Card, CardContent, Grid, Divider } from '@mui/material';

import RouterList from '../../../router';

const template = () => {
  return (
    <>
      <Stack spacing={8}>
        <Stack spacing={4}>
          <Typography variant="h2" component="h2">Interpretation and Definitions</Typography>
          <Box>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">Interpretation</Typography>
              <Typography variant="body1">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</Typography>
            </Stack>
          </Box>
          <Box>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">Definitions</Typography>
              <Typography variant="body1">For the purposes of this Privacy Policy:</Typography>
              <Typography variant="body1"><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</Typography>
              <Typography variant="body1"><strong>CliniOps Application Suite</strong> means the web and mobile applications that our users use to collect Clinical trial data</Typography>
              <Typography variant="body1"><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to CliniOps, Inc., 38750 Paseo Padre Pkwy, Suite C8, Fremont CA 94536.</Typography>
              <Typography variant="body1">For the purpose of the GDPR, the Company is the Data Controller for the information collected form our website (cliniops.com) and the Company is Data Processor for the information collected using CliniOps Application Suite.</Typography>
              <Typography variant="body1"><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</Typography>
              <Typography variant="body1"><strong>Country</strong> refers to: California, United States</Typography>
              <Typography variant="body1"><strong>Data Controller</strong>, for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.</Typography>
              <Typography variant="body1"><strong>Data Processor</strong>, The processor is defined in GDPR as “…a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller”. In clinical research this corresponds to anyone appointed by the Sponsor to work with the clinical trial, including CROs (project management, monitoring, data management, statistics, medical coding, medical writing, etc.) and Vendors (eCRF/EDC, ePRO, IVRS/IWRS, central labs, etc.).</Typography>
              <Typography variant="body1"><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</Typography>
              <Typography variant="body1"><strong>Do Not Track</strong> (DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing Internet users to control the tracking of their online activities across websites.</Typography>
              <Typography variant="body1"><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</Typography>
              <Typography variant="body1">For the purposes for GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity.</Typography>
              <Typography variant="body1"><strong>Service</strong> refers to the Website.</Typography>
              <Typography variant="body1"><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR, Service Providers are considered Data Processors.</Typography>
              <Typography variant="body1"><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</Typography>
              <Typography variant="body1"><strong>Website</strong> refers to CliniOps, accessible from https://cliniops.com/</Typography>
              <Typography variant="body1"><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</Typography>
              <Typography variant="body1">Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the User as you are the individual using the Service.</Typography>
            </Stack>
          </Box>
          <Typography variant="h2" component="h2">Collecting and Using Your Personal Data</Typography>
          <Box>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">Customer Data in CliniOps Application Suite</Typography>
              <Typography variant="body1">As part of CliniOps' applications and related services, our customer's employees and authorized users may enter information from or about their authorized users, employees, and clinical trial subjects (collectively, “Customer Data”), into our servers.</Typography>
              <Typography variant="body1">This Privacy Policy does not apply to our Customers' handling of their Data. . Our customers have their own policies regarding the collection, use and disclosure of your personal information. Our use of Customer Data is subject to the written contractual agreement for services between CliniOps and the customer that in no way involves the sale of your data. CliniOps' responsibility under that agreement - is the obligation to keep Customer Data safe and secure. To learn about how a particular customer handles your personal information, we encourage you to read that customer's privacy statement or contact that customer.</Typography>
              <Typography variant="body1">CliniOps has no ownership of Customer Data. Please direct any questions regarding Customer Data to the customer for which you work or which collected your information using CliniOps application Suite.</Typography>
            </Stack>
          </Box>
          <Box>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">Types of Data Collected</Typography>
              <Typography variant="h4" component="h4" pt={2}>Personal Data</Typography>
              <Typography variant="body1">In our website, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</Typography>
              <Typography variant="body1">
                <ul>
                  <li>Email address</li>
                  <li>First name and last name</li>
                  <li>Phone number</li>
                  <li>Usage Data</li>
                </ul>
              </Typography>

              <Typography variant="h4" component="h4" pt={2}>Usage Data</Typography>
              <Typography variant="body1">Usage Data is collected automatically when using the Service.</Typography>
              <Typography variant="body1">Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</Typography>
              <Typography variant="body1">When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</Typography>
              <Typography variant="body1">We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</Typography>

              <Typography variant="h4" component="h4" pt={2}>Tracking Technologies and Cookies</Typography>
              <Typography variant="body1">We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</Typography>
              <Typography variant="body1"><strong>Cookies or Browser Cookies:</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</Typography>
              <Typography variant="body1"><strong>Flash Cookies:</strong> Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how You can delete Flash Cookies, please read "Where can I change the settings for disabling, or deleting local shared objects?" available at <Link variant="link" href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html" target="_blank" underline="hover">https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html</Link></Typography>
              <Typography variant="body1"><strong>Web Beacons:</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</Typography>
              <Typography variant="body1">Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. You can learn more about cookies here: <Link variant="link" href="https://www.termsfeed.com/blog/cookies/" target="_blank" underline="hover">All about cookies by TermsFeed</Link>.</Typography>
              <Typography variant="body1">We use both Session and Persistent Cookies for the purposes set out below:</Typography>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="body1">
                    <strong>Necessary / Essential Cookies</strong>
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <Typography variant="body1">Type:</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1">Session Cookies</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1">Administered by:</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1">Us</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1">Purpose:</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1">These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="body1">
                    <strong>Cookies Policy / Notice Acceptance Cookies</strong>
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <Typography variant="body1">Type:</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1">Persistent Cookies</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1">Administered by:</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1">Us</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1">Purpose:</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1">These Cookies identify if users have accepted the use of cookies on the Website.</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="body1">
                    <strong>Functionality Cookies</strong>
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <Typography variant="body1">Type:</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1">Persistent Cookies</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1">Administered by:</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1">Us</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1">Purpose:</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1">These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="body1">
                    <strong>Tracking and Performance Cookies</strong>
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <Typography variant="body1">Type:</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1">Persistent Cookies</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1">Administered by:</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1">Us</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1">Purpose:</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1">These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new pages, features or new functionality of the Website to see how our users react to them.</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Typography variant="body1">For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</Typography>
            </Stack>
          </Box>
          <Box>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">Use of Your Personal Data</Typography>
              <Typography variant="body1">The Company may use Personal Data for the following purposes:</Typography>
              <Typography variant="body1">
                <ul>
                  <li><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</li>
                  <li><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
                  <li><strong>For the performance of a contract:</strong> The development, compliance and undertaking for the products, items or services You have purchased or of any other contract with Us through the Service.</li>
                  <li><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
                  <li><strong>To provide You</strong> with general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</li>
                  <li><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</li>
                  <li><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</li>
                  <li><strong>For other purposes</strong> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, marketing and your experience.</li>
                </ul>
              </Typography>
              <Typography variant="body1">We may share Your personal information in the following situations:</Typography>
              <Typography variant="body1">
                <ul>
                  <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</li>
                  <li><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
                  <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include any subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
                  <li><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
                  <li><strong>With Your consent:</strong> We may disclose Your personal information for any other purpose with Your consent.</li>
                </ul>
              </Typography>
            </Stack>
          </Box>
          <Box>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">Retention of Your Personal Data</Typography>
              <Typography variant="body1">The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</Typography>
              <Typography variant="body1">The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</Typography>
            </Stack>
          </Box>
          <Box>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">Transfer of Your Personal Data</Typography>
              <Typography variant="body1">Your information, including Personal Data, is processed at the Company's operating offices . It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</Typography>
              <Typography variant="body1">Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</Typography>
              <Typography variant="body1">The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</Typography>
            </Stack>
          </Box>
          <Box>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">Disclosure of Your Personal Data</Typography>
              <Typography variant="h4" component="h4" pt={2}>Business Transactions</Typography>
              <Typography variant="body1">If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</Typography>
              <Typography variant="h4" component="h4" pt={2}>Law enforcement</Typography>
              <Typography variant="body1">Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</Typography>
              <Typography variant="h4" component="h4" pt={2}>Other legal requirements</Typography>
              <Typography variant="body1">The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</Typography>
              <Typography variant="body1">
                <ul>
                  <li>Comply with a legal obligation</li>
                  <li>Protect and defend the rights or property of the Company</li>
                  <li>Prevent or investigate possible wrongdoing in connection with the </li>Service
                  <li>Protect the personal safety of Users of the Service or the public</li>
                  <li>Protect against legal liability</li>
                </ul>
              </Typography>
            </Stack>
          </Box>
          <Box>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">Security of Your Personal Data</Typography>
              <Typography variant="body1">The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</Typography>
            </Stack>
          </Box>
          
          <Typography variant="h2" component="h2">Detailed Information on the Processing of Your Personal Data</Typography>
          <Typography variant="body1" style={{ marginTop: 2 }}>The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store, use, process and transfer information about Your activity on Our Service in accordance with their Privacy Policies.</Typography>
          <Box>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">Analytics</Typography>
              <Typography variant="body1">We may use third-party Service providers to monitor and analyze the use of our Service.</Typography>
              <Typography variant="h4" component="h4" pt={2}>Google Analytics</Typography>
              <Typography variant="body1">Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.</Typography>
              <Typography variant="body1">You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.</Typography>
              <Typography variant="body1">For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page: <Link variant="link" href="https://policies.google.com/privacy" target="_blank" underline="hover">https://policies.google.com/privacy</Link></Typography>
            </Stack>
          </Box>
          <Typography variant="h2" component="h2">GDPR Privacy</Typography>
          <Box>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">Legal Basis for Processing Personal Data under GDPR</Typography>
              <Typography variant="body1">We may process Personal Data under the following conditions:</Typography>
              <Typography variant="body1"><strong>Consent:</strong> You have given Your consent for processing Personal Data for one or more specific purposes.</Typography>
              <Typography variant="body1"><strong>Performance of a contract:</strong> Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.</Typography>
              <Typography variant="body1"><strong>Legal obligations:</strong> Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.</Typography>
              <Typography variant="body1"><strong>Vital interests:</strong> Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.</Typography>
              <Typography variant="body1"><strong>Public interests:</strong> Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.</Typography>
              <Typography variant="body1"><strong>Legitimate interests:</strong> Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.</Typography>
              <Typography variant="body1">In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</Typography>
            </Stack>
          </Box>
          <Box>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">Your Rights under the GDPR</Typography>
              <Typography variant="body1">The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.</Typography>
              <Typography variant="body1">You have the right under this Privacy Policy, and by law if You are within the EU, to:</Typography>
              <Typography variant="body1"><strong>Request access to Your Personal Data:</strong> The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account settings section. If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.</Typography>
              <Typography variant="body1"><strong>Request correction of the Personal Data that We hold about You:</strong> You have the right to have any incomplete or inaccurate information We hold about You corrected.</Typography>
              <Typography variant="body1"><strong>Object to processing of Your Personal Data:</strong> This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.</Typography>
              <Typography variant="body1"><strong>Request erasure of Your Personal Data:</strong> You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.</Typography>
              <Typography variant="body1"><strong>Request the transfer of Your Personal Data:</strong> We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.</Typography>
              <Typography variant="body1"><strong>Withdraw Your consent:</strong> You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service.</Typography>
            </Stack>
          </Box>
          <Box>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">Exercising of Your GDPR Data Protection Rights</Typography>
              <Typography variant="body1">You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please note that we may ask You to verify Your identity before responding to such requests. If You make a request, We will try our best to respond to You as soon as possible.</Typography>
              <Typography variant="body1">You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA.</Typography>
            </Stack>
          </Box>
          <Typography variant="h2" component="h2">"Do Not Track" Policy as Required by California Online Privacy Protection Act: (CalOPPA)</Typography>
          <Box style={{ marginTop: 2 }}>
            <Stack spacing={1}>
              <Typography variant="body1">Our Service does not respond to Do Not Track signals.</Typography>
              <Typography variant="body1">However, some third party websites do keep track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.</Typography>
            </Stack>
          </Box>
          <Typography variant="h2" component="h2">Children's Privacy</Typography>
          <Box style={{ marginTop: 2 }}>
            <Stack spacing={1}>
              <Typography variant="body1">Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</Typography>
              <Typography variant="body1">If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</Typography>
            </Stack>
          </Box>
          <Typography variant="h2" component="h2">Links to Other Websites</Typography>
          <Box style={{ marginTop: 2 }}>
            <Stack spacing={1}>
              <Typography variant="body1">Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</Typography>
              <Typography variant="body1">We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</Typography>
            </Stack>
          </Box>
          <Typography variant="h2" component="h2">Changes to this Privacy Policy</Typography>
          <Box style={{ marginTop: 2 }}>
            <Stack spacing={1}>
              <Typography variant="body1">We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</Typography>
              <Typography variant="body1">We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</Typography>
              <Typography variant="body1">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</Typography>
            </Stack>
          </Box>
          <Typography variant="h2" component="h2">Representation for data subjects in the EU and the UK</Typography>
          <Box style={{ marginTop: 2 }}>
            <Stack spacing={1}>
              <Typography variant="body1">We value your privacy and your rights as a data subject and have therefore appointed Prighter as our privacy representative and your point of contact. Prighter gives you an easy way to exercise your privacy-related rights (e.g. requests to access or erase personal data). If you want to contact us via our representative Prighter or make use of your data subject rights, please visit: <Link variant="link" href="https://prighter.com/q/13827389066" target="_blank" underline="hover">https://prighter.com/q/13827389066</Link></Typography>
            </Stack>
          </Box>
          <Typography variant="h2" component="h2">Contact Us</Typography>
          <Box style={{ marginTop: 2 }}>
            <Stack spacing={1}>
              <Typography variant="body1">If you have any questions about this Privacy Policy, You can contact us:</Typography>
              <Typography variant="body1">By email: <Link variant="link" href="mailto:dpo@cliniops.com" underline="hover">dpo@cliniops.com</Link></Typography>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </>
  );
}

export default template;