import React from 'react';
import { Box, Typography, Grid, Card, CardHeader, CardMedia, Link } from '@mui/material';

import { blueGrey } from '@mui/material/colors';

import NewsArticle from '../../../component/news-article';
import Imgfob from '../../../../img/media/newsroom/_2023/image-logos.png';
import Img20220901 from '../../../../img/media/newsroom/_2024/20220901.png';
import ImgResearchAndMarkets from '../../../../img/media/newsroom/_2021/Media-2018-08.png';
import Imgdia from '../../../../img/media/newsroom/_2023/DIA-Logo.png';
import ImgWorldVaccineCongress from '../../../../img/media/newsroom/_2024/World_Vaccine_Congress-DC.png';
import Imguspto from '../../../../img/media/newsroom/_2023/USPTO-Logo.png';
import ImgYahooFinance from '../../../../img/media/newsroom/_2023/Yahoo_Finance_logo.png';
import ImgOpenPR from '../../../../img/media/newsroom/_2024/OpenPR.png';
import ImgCUDenver from '../../../../img/media/newsroom/_2024/CU_Denver_logo.png';
import ImgForbesBusinessCouncil from '../../../../img/media/newsroom/_2024/Forbes_BusinessCouncil.png'

const template = () => {
  return (
    <>
      <Box id="2024">
        <Box sx={{ mb: 1 }}>
          <Typography variant="caption" color={blueGrey[200]}>
            <strong>2024</strong>
          </Typography>
        </Box>
        <Grid container spacing={4}>
        <NewsArticle
              img={ImgForbesBusinessCouncil}
              alt="Article Header Image"
              link="https://www.forbes.com/councils/forbesbusinesscouncil/2024/09/25/20-ways-business-leaders-can-increase-their-financial-knowledge/"
              title="20 Ways Business Leaders Can Increase Their Financial Knowledge"
              date="September 25, 2024"
          />
        <NewsArticle
              img={ImgForbesBusinessCouncil}
              alt="Article Header Image"
              link="https://www.forbes.com/councils/forbesbusinesscouncil/2024/09/24/counting-africa-in-for-clinical-trials/"
              title="Counting Africa In For Clinical Trials"
              date="September 24, 2024"
          />
        <NewsArticle
              img={ImgOpenPR}
              alt="Article Header Image"
              link="https://www.openpr.com/news/3562123/current-and-future-analysis-of-clinical-operation-platform"
              title="Current and Future Analysis of Clinical Operation Platform Market With New Business Strategies and Forecast by 2031 | Veeva Systems, Seascape Clinical, CliniOps, Medable"
              date="July 02, 2024"
          />
          <NewsArticle
              img={Imgdia}
              alt="Article Header Image"
              link="https://www.diaglobal.org/en/flagship/dia-2024"
              title="DIA Global, 2024"
              date="June 16, 2024"
          /> 
          <NewsArticle
              img={ImgYahooFinance}
              alt="Article Header Image"
              link="https://finance.yahoo.com/news/cliniops-awarded-patent-innovative-offline-150000970.html"
              title="CliniOps Awarded Patent for an Innovative Offline/Online eSource, or Direct Data Collection (DDC) System, for Global Clinical Trials"
              date="April 25, 2024"
          /> 
          <NewsArticle
              img={Imguspto}
              alt="Article Header Image"
              link="https://ppubs.uspto.gov/dirsearch-public/print/downloadPdf/11967402"
              title="CliniOps Platform receives Patent approval from USPTO, for offline data collection and synchronization for managing a clinical trial"
              date="April 23, 2024"
          /> 
          <NewsArticle
              img={Imgfob}
              alt="Article Header Image"
              link="https://www.terrapinn.com/conference/festival-of-biologics-usa/speaker-avik-PAL.stm"
              title="Festival of Biologics USA, 2024"
              date="April 15, 2024"
          /> 
          <NewsArticle
            img={ImgWorldVaccineCongress}
            alt="Article Header Image"
            link="https://www.terrapinn.com/conference/world-vaccine-congress-washington/index.stm"
            title="World Vaccine Congress, 2024"
            date="April 1, 2024"
          /> 
          <NewsArticle
            img={Img20220901}
            alt="Article Header Image"
            link="https://www.theceo.in/business-experts/celebrating-national-science-day-insights-and-reflections-from-industry-leaders"
            title="Celebrating National Science Day: Insights and Reflections from Industry Leaders"
            date="February 28, 2024"
          /> 
          <NewsArticle
            img={ImgResearchAndMarkets}
            alt="Article Header Image"
            link="https://www.researchandmarkets.com/reports/5139503/epro-e-patient-diaries-and-ecoa-global"
            title="Global ePRO, E-Patient Diaries and eCOA Market to Reach $4.7 Billion by 2030"
            date="February 23, 2024"
          /> 
          <NewsArticle
            img={ImgCUDenver}
            alt="Article Header Image"
            link="https://research.cuanschutz.edu/cros/novel-clinical-trials#ac-cliniops-1"
            title="Driving innovative research to transform novel ideas to better human life"
            date="January 10, 2024"
          />   
        </Grid>                
      </Box>
    </>
  );
}

export default template;