import React from "react";
import { Grid, Box, Typography, Stack,Alert, Link } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";

import { PageHero, PageContent } from "../../component/page";
import ImgAppolinaire from "../../../img/media/customer-story/_1/AppolinaireTiam.jpg";
import Imgaids from "../../../img/media/customer-story/_1/AIDS2020.png";
import Imgcroi from "../../../img/media/customer-story/_1/CROI.png";
import Imghivped from "../../../img/media/customer-story/_1/HIVPED.png";

// Page Imports
import _0 from "./customer-story_8/_0";

const template = () => {
  return (
    <>
      <PageHero color={teal}>
        <_0 />
      </PageHero>
      <PageContent>
        <Grid item>
          <Typography variant="h4" paragraph>
          A cluster randomized trial to improve Maternal-Child Outcomes for HIV-positive pregnant women in Lesotho, Africa (IMPROVE), using Disruptive Technologies</Typography>
          <Typography paragraph>
          When approach is participatory and multidisciplinary, particularly for a clinical trial involving 1,020 participants in 15 different sites, the need for smart technology is significant. 
          </Typography>
          <Typography paragraph>
          In July 2016, Elizabeth Glaser Pediatric AIDS Foundation, in collaboration with Ministry of Health, Lesotho, United States Agency for International Development (USAID), Avenir Health Population Council and Johns Hopkins University started an interventional randomized clinical trial to improve maternal and child health (MCH) outcomes by implementing and evaluating a multidisciplinary "Integrated Management Team to Improve Maternal-Child Outcomes (IMPROVE)" intervention to increase MCH, antiretroviral therapy (ART), and HIV services uptake and retention in Lesotho.
          </Typography>
          <Typography paragraph>
          For efficient recruitment and participatory-based engagement of patients with the study team and other stakeholders in the communities, CliniOps’ Platform with e-Source and EDC, purpose built for emerging conditions with complete offline capability, were successfully deployed for the study.
          </Typography>

          <Typography paragraph>
          A cohort of HIV-positive and HIV-negative pregnant women were enrolled and prospectively followed every three months through pregnancy and until the participant's child reached 24 months of age.  By using smart tablets in multiple mobile sites in Lesotho, the study team was able to enter eCRF data for each participant and track visits even if participants moved to other villages.  With bar codes assigned to each participant, the mobile clinics in other villages were able to pull up specific data and enter new information based on visit by participants in relocated sites. Without the CliniOps unified platform, any relocation by any participant would have been considered “lost to follow up”. The study was successfully completed in September 2020.  
          </Typography>

          <Typography paragraph>
          According to Avik Pal, CEO, CliniOps, “Disruptive technologies in clinical trials continually evolve to respond to real time needs. For public health studies like HIV, where sites are remote and physically difficult to access, use of offline smart applications for recruitment are deployed with much success to reach and recruit thousands of participants. Many studies continued with minimal or zero disruption during pandemic with the calibration of newly developed decentralized technologies to engage patients safely and efficiently.”    
          </Typography>

        
          <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Stack
              spacing={4}
              sx={{ pl: 4 }}
              direction={{ xs: "column", md: "row" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "220px",
                  width: "220px",
                  height: "220px",
                  borderRadius: 2,
                  backgroundImage: `url(${ImgAppolinaire})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                }}
              />
              <Box>
            <Typography paragraph>
              <em>
                "IMPROVE study is part of EGPAF’s continued commitment to advance HIV research on effective and evidence based interventions that benefit children and families. At the outset, we recognized the need to deploy technology that would enable our mobile clinics and site coordinators to sustainably engage with and monitor participants until their children reach 2 years old. Our collaboration with CliniOps made it possible for us to use smart solutions to recruit participants in remote sites as well as track their tests and visits efficiently in complete offline mode. The accomplishment of the study, with assistance from CliniOps technology platform, is another step towards our objective to strengthen local healthcare response to needs of mother, child, or family affected by HIV."</em>
            </Typography>
            <Typography variant="overline" paragraph>
              <strong>
                Dr. Appolinaire Tiam
                <br />
                Vice President, Technical Strategies and Innovation, Elizabeth Glaser Pediatric AIDS Foundation
              </strong>
            </Typography>
          </Box>
            </Stack>
          </blockquote>
        
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2
                }}
            ><Typography paragraph><b>POPULATION COUNCIL</b></Typography>
            </Box>
            <Box>
            <Typography paragraph>
            A publication titled, <Link href="https://knowledgecommons.popcouncil.org/cgi/viewcontent.cgi?article=1551&context=departments_sbsr-hiv" target={"_blank"} underline="hover">"Optimizing maternal and child health outcomes through use of multidisciplinary 'IMPROVE' teams in Lesotho"</Link>, and CliniOps’s involvement therein, was published in Project SOAR Final Report. Washington, DC: Population Council.</Typography>
            <Typography>Authors: Tiam Appolinaire, Vincent Tukei, Lauren Greenberg, Shannon Viana, Heather Hoffman, Laura Guay, Ramatlapeng Thabelo, Tsietso Mots'oane, and Matsepeli Nchephe.</Typography>
            </Box>
        </Stack>
        </Alert>
        
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    marginTop: '30px',
                    marginBottom: '30px'
                }}          
            ><Typography><b>ELIZABETH GLASER PEDIATRIC AIDS FOUNDATION</b></Typography></Box>
            <Box>
            <Typography paragraph>
            <Link href="https://pedaids.org/wp-content/uploads/2021/12/IMPROVE-Results-Brief-14Dec2021.pdf" target={"_blank"} underline="hover">Research Results brief: The IMPROVE study in Lesotho</Link>, An integrated management team intervention package to improve maternal and child health outcomes, was published by Pediatric Aids Foundation
            </Typography>
            </Box>
        </Stack>
        </Alert>

        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${Imgaids})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
          
           <Box>
           <Typography paragraph>
           Presented at the <Link href="https://www.aids2020.org/" target={"_blank"} underline="hover">AIDS 2020</Link>, International AIDS Conference, titled “In the era of test and treat for pregnant and breastfeeding women; what has changed in the pregnancy and delivery outcomes among HIV positive women compared HIV negative women: Results of a cluster randomized intervention study in Lesotho”.
           </Typography>
           <Typography paragraph>
           Presenters: V Tukei, A Tiam, L Greenberg, H Hoffman, T Ramatlapeng, M Nchepe, T Mots’oane, F Mohai, A Knowlton, L Bollinger, A Adesina, A Chabela, M Masitha, L Mofenson, L Guay
           </Typography>
           </Box>
          </Stack>
        </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2,
                    backgroundImage: `url(${Imgaids})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
            />
            <Box>
            <Typography paragraph>
            Presented at the <Link href="https://www.aids2020.org/" target={"_blank"} underline="hover">AIDS 2020</Link>, International AIDS Conference, titled “Results of a cluster randomized study to evaluate the effect of a comprehensive service delivery intervention on HIV and maternal child health outcomes in HIV-positive and HIV-negative women.
            </Typography>
            <Typography>
            Presenters: Appolinaire Tiam, Vincent Tukei, Lauren Greenberg, Heather Hoffman, Shannon Viana, Lynne Mofenson, Thabelo Ramatlapeng, Tsietso Mots’oane, Matsepeli Nchephe, Laura Guay.
            </Typography>
            </Box>
        </Stack>
        </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '120px',
                    borderRadius: 2,
                    backgroundImage: `url(${Imgcroi})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
            />
            <Box>
            <Typography paragraph>
            Presented at the <Link href="https://www.croiconference.org/croi-2020/" target={"_blank"} underline="hover">CROI 2020</Link> Conference, titled “High viral suppression among HIV positive postpartum women: Cluster Randomized trial”
            </Typography>
            <Typography>
            Presenters: A Tiam, H Hoffman, L Greenberg, V Tukei, M Masitha, T Ramatlapeng, M Nchepe, T. Mots’oane, A Chabela, L Guay
            </Typography>
            </Box>
        </Stack>
        </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '75px',
                    borderRadius: 2,
                    backgroundImage: `url(${Imghivped})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
            />
            <Box>
            <Typography paragraph>
            Presented at the International Workshop on HIV Pediatrics 2020, titled <Link href="https://academicmedicaleducation.com/meeting/international-workshop-hiv-pediatrics-2020/e-poster/results-cluster-randomized-study" target={"_blank"} underline="hover">“Results of a Cluster Randomized Study to Evaluate the Effect of a Comprehensive Service Delivery Intervention on HIV and Maternal Child Health Outcomes in HIV-Positive and HIV-Negative Women”</Link>.
            </Typography>
            <Typography>
            Presenters: Appolinaire Tiam, Vincent Tukei, Lauren Greenberg, Heather Hoffman, Shannon Viana, Lynne Mofenson, Thabelo Ramatlapeng, Tsietso Mots’oane, Matsepeli Nchephe, Laura Guay
            </Typography>
            </Box>
        </Stack>
        </Alert>
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '80px',
                    borderRadius: 2
                    /*backgroundImage: `url(${ImgPEPFAR})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'*/
                }}
            ><Typography paragraph><b>PEPFAR, USAID</b></Typography>
            </Box>
            {/*<Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '114px',
                    width: '114px',
                    height: '80px',
                    borderRadius: 2,
                    marginTop:'30px !important',
                    backgroundImage: `url(${ImgUSAID})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center'
                }}
            ><Typography fontSize={"12px"}><b>USAID</b></Typography>
            </Box>*/}
            <Box>
            <Typography paragraph>
            The study was co-sponsored by the U.S. President's Emergency Plan for AIDS Relief <Link href="https://www.state.gov/pepfar/" target={"_blank"} underline="hover">(PEPFAR)</Link>, and The U.S. Agency for International Development <Link href="https://www.usaid.gov/" target={"_blank"} underline="hover">(USAID)</Link> 
            </Typography>
            
            </Box>
        </Stack>
        </Alert>
        
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
                xs="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '175px',
                    width: '175px',
                    height: '55px',
                    borderRadius: 2,
                    marginTop: '30px',
                    marginBottom: '30px'
                }}          
            ><Typography><b>MINISTRY OF HEALTH, LESOTHO, JOHNS HOPKINS UNIVERSITY</b></Typography></Box>
            <Box>
            <Typography paragraph>
            The study was conducted in collaboration with <Link href="http://health.gov.ls/" target={"_blank"} underline="hover">Ministry of Health, Lesotho</Link>, <Link href="https://www.jhu.edu/" target={"_blank"} underline="hover">Johns Hopkins University</Link>
            </Typography>
            </Box>
        </Stack>
        </Alert>
    

        {/* </Alert> */}

          {/* <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Box sx={{ pl: 4 }}>
              <Typography paragraph>
                A Case Study titled ‘Use of Decentralized Clinical Technologies
                in Lower Urinary Tract Symptoms’ is published in the Journal of
                Medicine, University of Santo Tomas, in the October 2022 issue,
                authored by Dr. Nadina Jose & Avik Pal
              </Typography>
            </Box>
          </blockquote> */}
        </Grid>
      </PageContent>
    </>
  );
};

export default template;
