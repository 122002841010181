import React from 'react';
import { Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const template = () => {
  return (
    <>
      <Typography variant="h1" component="h1">
      Unified Technology Platform support for Phase 1B dose-escalation study on subjects with chronic kidney disease (CKD)
      </Typography>
      <Typography variant="body2" color={blueGrey[400]}>
      <a href="https://www.renibus.com/" target={'_blank'} class="linkcolor">Renibus</a>
      </Typography>
    </>
  );
}

export default template;