import React from "react";
import { Grid, Box, Typography, Stack,Alert, Link } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";

import { PageHero, PageContent } from "../../component/page";
import ImgCJASN from "../../../img/media/customer-story/_1/CJASN.png";
import ImgBhupinder from "../../../img/media/customer-story/_1/DrBhupinder.png";





// Page Imports
import _0 from "./customer-story_12/_0";

const template = () => {
  return (
    <>
      <PageHero color={teal}>
        <_0 />
      </PageHero>
      <PageContent>
        <Grid item>
          <Typography paragraph>
          How can technology be effectively deployed in clinical trials that are to be conducted sequentially to test a new therapy? There are many answers to this question, but only when technologies are unified in a platform will efficiency truly benefit the studies and lessen burden for all stakeholders: sponsors, CROs, study teams and participants. Efficiency is key.
          </Typography>
          <Typography paragraph>
          Three Phase 1B studies with separate cohorts of healthy volunteers and participants affected by chronic kidney disease (CKD) stages 3-4 were successfully concluded. These interventional dose escalating studies involved clinical trials with Iron Sucrose (FeS), Stannous Protoporphyrin (SnPP), and RBT-1. From 2018 to 2020, the studies were each conducted in three month durations and involved a total of  178 participants. 
          </Typography>

          <Typography paragraph>
          The sponsor, Renibus Therapeutics Inc, is a clinical-stage biotech company, committed to developing breakthrough therapies for cardiorenal diseases by  preventing disease progression and protecting against organ damage. Cardiothoracic surgical patients stand to benefit from these therapies.
          </Typography>

          <Typography paragraph>
          As technology provider, CliniOps made sure that its electronic data capture (EDC), clinical data management platform, lab data management and biostatics services were fully accessible in a unified platform and integrated with other study solutions.  This means that data from clinical sites and labs were electronically captured and made available on real time for monitoring, management and analysis for the study team. Data was captured in smart devices digitally at the point of care. And when connectivity was a temporary challenge, data entry was still possible in complete offline mode and easily integrated as soon as connectivity was restored.  
          </Typography>
          

          <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Stack
              spacing={4}
              sx={{ pl: 4 }}
              direction={{ xs: "column", md: "row" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "220px",
                  width: "220px",
                  height: "250px",
                  borderRadius: 2,
                  backgroundImage: `url(${ImgBhupinder})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                }}
              />
              <Box>
            <Typography paragraph>
              <em>
              “Renibus is a clinical stage biopharmaceutical company with novel programs to halt and manage progression of cardiorenal diseases. For our three sequential  Phase 1B studies, we needed to be very efficient as we tested our therapies to ultimately improve patient outcomes. Application of CliniOps’ unified technologies was  valuable for the seamless conduct of the studies and consequently helped to lessen the burden for both study team and participants”</em>
            </Typography>
            <Typography variant="overline" paragraph>
              <strong>
              Bhupinder Singh, MD,
              <br></br>
              Chief Medical Officer, Renibus Therapeutics
              </strong>
            </Typography>
          </Box>
            </Stack>
          </blockquote>

        
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${ImgCJASN})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box>
            <Typography paragraph>
            The results of the clinical study,  <Link href="https://journals.lww.com/cjasn/fulltext/2020/05000/a_pharmacologic__stress_test__for_assessing_select.11.aspx" target={"_blank"} underline="hover"> “A Pharmacologic “Stress Test” for Assessing Select Antioxidant Defenses in Patients with CKD”</Link> was published in the Clinical Journal of American Society of Nephrology, in May, 2020.</Typography>
            </Box>
        </Stack>
        </Alert>
        
        </Grid>
      </PageContent>
    </>
  );
};

export default template;
