import React from 'react';
import { Box, Typography, Grid, Card, CardHeader, CardMedia, Link } from '@mui/material';

import { blueGrey } from '@mui/material/colors';

import NewsArticle from '../../../component/news-article';

import ImgSCDM from '../../../../img/media/newsroom/_2019/Media-2019-01.png';
import ImgFrostSullivan from '../../../../img/media/newsroom/_2019/Media-2019-02.png';
import ImgGartner from '../../../../img/media/newsroom/_2019/Media-2019-03.png';
import ImgPubMed from '../../../../img/media/newsroom/_2019/Media-Publications-01.png';
import ImgFDA from '../../../../img/media/newsroom/_2019/Media-2019-04.png';
import ImgPlos from '../../../../img/media/newsroom/_2019/Media-2019-06.png';
import ImgUSISPF from '../../../../img/media/newsroom/_2019/Media-2019-15.png';
import ImgDia from '../../../../img/media/newsroom/_2019/Media-2019-07.png';
import ImgIITBayAreaAlumni from '../../../../img/media/newsroom/_2019/Media-2019-08.png';
import ImgTechnologyHeadlines from '../../../../img/media/newsroom/_2019/Media-2019-09.png';
import ImgRedHerringTop100 from '../../../../img/media/newsroom/_2019/Media-2019-10.png';
import ImgResearchMarkets from '../../../../img/media/newsroom/_2019/Media-2019-12.png';
import ImgAfricanMediaAgency from '../../../../img/media/newsroom/_2019/Media-2019-11.png';
import ImgGlobalExcellenceAwards from '../../../../img/media/newsroom/_2019/Media-2019-13.png';
import ImgEndocrinology from '../../../../img/media/newsroom/_2019/Media-2019-14.png';
import ImgVibrantGujaratSummit from '../../../../img/media/newsroom/_2019/Media-2019-16.png';
import ImgPlosNTD from '../../../../img/media/newsroom/_2023/PLOS-NTD_Logo.png';

const template = () => {
  return (
    <>
      <Box id="2019">
        <Box sx={{ mb: 1 }}>
          <Typography variant="caption" color={blueGrey[200]}>
            <strong>2019</strong>
          </Typography>
        </Box>
        <Grid container spacing={4}>
          <NewsArticle
            img={ImgSCDM}
            alt="Article Header Image"
            link="https://scdm.org/scdm-2019-annual-conference/"
            title="SCDM Annual Conference, 2019"
            date="September 30, 2019"
          />
          <NewsArticle
            img={ImgFrostSullivan}
            alt="Article Header Image"
            link="https://www.prnewswire.com/in/news-releases/frost-amp-sullivan-s-asia-pacific-best-practices-awards-honors-the-top-companies-in-the-region-807367226.html"
            title="Digitization of Clinical Trials Improving Trial Efficiency"
            date="August 30, 2019"
          />
          <NewsArticle
            img={ImgGartner}
            alt="Article Header Image"
            link="https://www.gartner.com/en/documents/3955926"
            title="Featured in the Gartner Hype Cycle for Life Science R&D"
            date="August 02, 2019"
          />
          <NewsArticle
            img={ImgPlosNTD}
            alt="Article Header Image"
            link="https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0007541"
            title="Dosing pole recommendations for lymphatic filariasis elimination: A height-weight quantile regression modeling approach"
            date="July 17, 2019"
          />
          <NewsArticle
            img={ImgFDA}
            alt="Article Header Image"
            link="https://fedscoop.com/fda-innovate-today-summit-2019/"
            title="FDA Innovate Today Summit 2019"
            date="July 17, 2019"
          />
          <NewsArticle
            img={ImgPlos}
            alt="Article Header Image"
            link="https://journals.plos.org/plosmedicine/article?id=10.1371/journal.pmed.1002839"
            title="The safety of double- and triple-drug community mass drug administration for lymphatic filariasis: A multicenter, open-label, cluster-randomized study"
            date="June 24, 2019"
          />
          <NewsArticle
            img={ImgUSISPF}
            alt="Article Header Image"
            link="https://www.pmindia.gov.in/en/news_updates/us-india-strategic-partnership-forum-calls-on-pm/"
            title="USISPF Delegation to India led by John Chambers"
            date="June 24, 2019"
          />
          <NewsArticle
            img={ImgDia}
            alt="Article Header Image"
            link="https://dia2019globalannualmeeting.sched.com/event/KfY4"
            title="DIA Conference 2019"
            date="June 24, 2019"
          />
          <NewsArticle
            img={ImgIITBayAreaAlumni}
            alt="Article Header Image"
            link="https://www.iit-bayarea.org/tracks/Healthcare-and-Medicine-Through-Large-Scale-Data-Analytics"
            title="IIT Bay Area Alumni Conference 2019"
            date="June 15, 2019"
          />
          <NewsArticle
            img={ImgRedHerringTop100}
            alt="Article Header Image"
            link="https://www.redherring.com/red-herring-2019-top-100-north-america-winners-press-release/"
            title="Red Herring Top 100 North America Winners"
            date="May 15, 2019"
          />
          <NewsArticle
            img={ImgResearchMarkets}
            alt="Article Header Image"
            link="https://www.businesswire.com/news/home/20190429005805/en/Pharma-Clinical-Trial-Digitization---Companies-to-Action-2019---ResearchAndMarkets.com"
            title="Pharma Clinical Trial Digitization – Companies to Action, 2019"
            date="April 29, 2019"
          />
          <NewsArticle
            img={ImgAfricanMediaAgency}
            alt="Article Header Image"
            link="https://africa.com/how-kenyas-fight-against-elephantiasis-is-becoming-a-blueprint-for-africa-and-the-world"
            title="How Kenya’s Fight Against Elephantiasis is Becoming a Blueprint for Africa and the World"
            date="April 14, 2019"
          />
          <NewsArticle
            img={ImgGlobalExcellenceAwards}
            alt="Article Header Image"
            link="https://www.acquisition-international.com/issues/issue-4-2019/74/"
            title="‘Global Excellence Awards’ for Most Innovative in Clinical Trial Digitalization Solutions 2019 – USA"
            date="February 12, 2019"
          />
          <NewsArticle
            img={ImgEndocrinology}
            alt="Article Header Image"
            link="https://researchopenworld.com/a-polyherbal-indian-system-of-medicine-ayush-preparation-for-optimization-of-glycemic-control-in-newly-diagnosed-type-2-diabetes-and-prediabetes-a-multicenter-randomised-double-blind-placebo-co/"
            title="Endocrinology, A ‘Endocrinology, Diabetes and Metabolism Journal’ Publication"
            date="February 09, 2019"
          />
        </Grid>                
      </Box>
    </>
  );
}

export default template;