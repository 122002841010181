import React from 'react';
import { Box, Typography, Grid, Card, CardHeader, CardMedia, Link } from '@mui/material';

import { blueGrey } from '@mui/material/colors';

import NewsArticle from '../../../component/news-article';

import ImgMedhealthoutlook from '../../../../img/media/newsroom/_2022/medhealthoutlook.png';
import ImgRoche from '../../../../img/media/newsroom/_2022/Media-2020-21.jpg';
import Img360Research from '../../../../img/media/newsroom/_2022/360iresearch.png';
import ImgFeb012022 from '../../../../img/media/newsroom/_2022/20220201.png';
import ImgFeb022022 from '../../../../img/media/newsroom/_2022/20220202.png';
import ImgFeb282022 from '../../../../img/media/newsroom/_2022/20220228.png';
import ImgMay012022 from '../../../../img/media/newsroom/_2022/20220501.png';
import ImgMay182022 from '../../../../img/media/newsroom/_2022/20220518.png';
import ImgJune102022 from '../../../../img/media/newsroom/_2022/20220610.png';
import ImgJune142022 from '../../../../img/media/newsroom/_2022/20220614.png';
import ImgJune232022 from '../../../../img/media/newsroom/_2022/20220623.jpg';
import Img20220904 from '../../../../img/media/newsroom/_2022/20220904.png';
import Img20220830 from '../../../../img/media/newsroom/_2022/20220830.png';
import ImgGartner from '../../../../img/media/newsroom/_2021/Media-2019-03.png';
import Img20220915 from '../../../../img/media/newsroom/_2022/20220915.png';
import Img20220907 from '../../../../img/media/newsroom/_2022/20220907.png';
import Img20220912 from '../../../../img/media/newsroom/_2022/20220912.png';
import Img20220901 from '../../../../img/media/newsroom/_2022/20220901.png';
import Imgtotalhealth from '../../../../img/media/newsroom/_2022/Imgtotalhealth.png';
import ImgForbes from '../../../../img/media/newsroom/_2022/Forbes.png';
import ImgYahoo from '../../../../img/media/newsroom/_2022/ImgYahoo.png';
import Imgjournal from '../../../../img/media/newsroom/_2022/journal.png';
import Imgeverest from '../../../../img/media/newsroom/_2022/everest.jpg';
import ImgResearchAndMarkets from '../../../../img/media/newsroom/_2021/Media-2018-08.png';
import ImgPLOS from '../../../../img/media/newsroom/_2022/PLOS_ntd_h.png';



const template = () => {
  return (
    <>
      <Box id="2022">
        <Box sx={{ mb: 1 }}>
          <Typography variant="caption" color={blueGrey[200]}>
            <strong>2022</strong>
          </Typography>
        </Box>
        <Grid container spacing={4}>
        <NewsArticle
            img={ImgForbes}
            alt="Article Header Image"
            link="https://www.forbes.com/sites/forbestechcouncil/2022/11/11/stayin-alive-patient-centricity-in-decentralized-clinical-trials/?sh=413f0dd2792e"
            title="'Stayin' Alive': Patient Centricity In Decentralized Clinical Trials"
            date="November 11, 2022"
          /> 
          <NewsArticle
            img={ImgResearchAndMarkets}
            alt="Article Header Image"
            link="https://www.businesswire.com/news/home/20221109005651/en/Global-Virtual-Clinical-Trials-Global-Market-Report-2022-Increasing-Adoption-of-Telehealth-Driving-Growth---ResearchAndMarkets.com"
            title="Global Virtual Clinical Trials Global Market Report 2022: Increasing Adoption of Telehealth Driving Growth"
            date="November 09, 2022"
          />
          <NewsArticle
            img={Imgeverest}
            alt="Article Header Image"
            link="https://www2.everestgrp.com/reportaction/EGR-2022-46-R-5610/Marketing"
            title="Decentralized Clinical Trial Platforms PEAK Matrix® Assessment 2023"
            date="November 09, 2022"
          /> 
          <NewsArticle
            img={Imgjournal}
            alt="Article Header Image"
            link="https://www.jmust.org/elib/journal/doi/10.35460/2546-1621.2022-0052/full"
            title="Case Study: Use of Decentralized Clinical Technologies in Lower Urinary Tract Symptoms"
            date="October 31, 2022"
          /> 
          <NewsArticle
            img={ImgYahoo}
            alt="Article Header Image"
            link="https://finance.yahoo.com/news/cliniops-recognized-gartner-hype-cycles-140000004.html"
            title="CliniOps Recognized in 'Gartner Hype Cycle' for Fifth straight year!"
            date="October 26, 2022"
          /> 
        <NewsArticle
            img={Imgtotalhealth}
            alt="Article Header Image"
            link="https://www.newswire.com/news/cliniops-announces-strategic-partnership-with-my-total-health-21853614"
            title="CliniOps Announces Strategic Partnership with My Total Health"
            date="October 20, 2022"
          /> 
        <NewsArticle
            img={Img20220915}
            alt="Article Header Image"
            link="https://www.exostar.com/product/secure-access-manager/"
            title="CliniOps joins Exostar Secure Access Manager (SAM) to enable Single Sign On (SSO)"
            date="October 01, 2022"
          />
        <NewsArticle
            img={Img20220915}
            alt="Article Header Image"
            link="https://www.exostar.com/press/cliniops-connects-with-exostar-to-deliver-a-unified-access-experience-for-digital-clinical-trials/"
            title="Centralized Access for Decentralized Trials"
            date="September 15, 2022"
          />
          <NewsArticle
            img={Img20220907}
            alt="Article Header Image"
            link="https://www.ahajournals.org/doi/abs/10.1161/CIRCEP.122.010857"
            title="Forward-Solution Noninvasive Computational Arrhythmia Mapping: The VMAP Study"
            date="September 7, 2022"
          />
          <NewsArticle
            img={Img20220904}
            alt="Article Header Image"
            link="https://www.ersnet.org/congress-and-events/congress/"
            title="European Respiratory Society, International Congress, 2022"
            date="September 4, 2022"
          />
          <NewsArticle
            img={Img20220901}
            alt="Article Header Image"
            link="https://magazine.theceo.in/technology-industry-and-business-leaders-food-and-processing-2022/"
            title="The CEO Magazine"
            date="September 1, 2022"
          />
           <NewsArticle
            img={Img20220830}
            alt="Article Header Image"
            link="https://www.fastcompany.com/90780899/are-hybrid-clinical-trials-a-pit-stop-as-the-pendulum-swings-to-decentralization"
            title="Are hybrid clinical trials a pit stop as the pendulum swings to decentralization?"
            date="August 30, 2022"
          />
          <NewsArticle
            img={ImgGartner}
            alt="Article Header Image"
            link="https://www.gartner.com/interactive/hc/4016952"
            title="Hype Cycle for Life Science Clinical Development, 2022"
            date="July 26, 2022"
          />
          <NewsArticle
            img={ImgMay012022}
            alt="Article Header Image"
            link="https://www.youtube.com/watch?v=SehYJmxSqMU"
            title="In Conversation with Avik Pal | CliniOps | The Enterprise World"
            date="July 11, 2022"
          />
          <NewsArticle
            img={ImgJune232022}
            alt="Article Header Image"
            link="https://crilaforprostate.com/pages/asp-2022-research"
            title="The American Society of Pharmacognosy Annual Conference, 2022"
            date="June 23, 2022"
          />
          <NewsArticle
            img={ImgJune142022}
            alt="Article Header Image"
            link="https://www2.everestgrp.com/reportaction/EGR-2022-46-R-5266/Marketing"
            title="Clinical Development Platforms Products PEAK Matrix® Assessment 2022"
            date="June 14, 2022"
          />
          <NewsArticle
            img={ImgJune102022}
            alt="Article Header Image"
            link="https://www.usatoday.com/story/sponsor-story/level-up/2022/06/10/meet-cutting-edge-tech-companies-disrupting-industry/7566391001/"
            title="Meet the Cutting edge tech companies disrupting the industry"
            date="June 10, 2022"
          />
          <NewsArticle
            img={ImgMay182022}
            alt="Article Header Image"
            link="https://www.linkedin.com/pulse/decentralized-studies-victory-sue-mckinney/"
            title="Decentralized Studies Victory"
            date="May 18, 2022"
          />
          <NewsArticle
            img={ImgMedhealthoutlook}
            alt="Article Header Image"
            link="https://medhealthoutlook.com/digital-health-solution-providers-list-2022/"
            title="Top 10 Digital Health Solution Providers 2022"
            date="May 11, 2022"
          />
          <NewsArticle
            img={ImgMay012022}
            alt="Article Header Image"
            link="https://theenterpriseworld.com/digital-health-solution-providers-in-2022/"
            title="Most Promising Digital Health Solution Providers in 2022"
            date="May 1, 2022"
          />
          <NewsArticle
            img={ImgRoche}
            alt="Article Header Image"
            link="https://www.roche.com/"
            title="Futurist Series talk # 1 - Pharma’s approach to Decentralized Trials"
            date="April 19, 2022"
          />
          <NewsArticle
            img={ImgFeb282022}
            alt="Article Header Image"
            link="https://www.fastcompany.com/90722848/16-ways-to-manage-your-companys-image-from-the-inside-out"
            title="15 ways to manage your company’s image from the inside out"
            date="February 28, 2022"
          />
          <NewsArticle
            img={ImgPLOS}
            alt="Article Header Image"
            link="https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0010096"
            title="Safety and efficacy of mass drug administration with a single-dose triple-drug regimen of albendazole + diethylcarbamazine + ivermectin for lymphatic filariasis in Papua New Guinea: An open-label, cluster-randomised trial"
            date="February 9, 2022"
          />
          <NewsArticle
            img={ImgFeb022022}
            alt="Article Header Image"
            link="https://www.fastcompany.com/90722550/15-ways-to-crush-quarterly-goals-in-2022"
            title="14 ways to crush quarterly goals in 2022"
            date="February 2, 2022"
          />
          <NewsArticle
            img={ImgGartner}
            alt="Article Header Image"
            link="https://www.gartner.com/document/3989224"
            title="Life Science CIOs: Map Your Pathway to Digital Trials"
            date="January 17, 2022"
          />
        </Grid>                
      </Box>
    </>
  );
}

export default template;