import React from 'react';
import { Grid } from '@mui/material';

import RouterList from '../../../router';
import PressRelease from '../../../component/press-release';

// import ImgDiversity from '../../../../img/media/blog/diversity.jpg';
// import ImgClinicalTrial from '../../../../img/media/blog/clinical-trials.png';
// import ImgInitialImpact from '../../../../img/media/blog/initial-impace.jpg';
// import ImgTelemedicine from '../../../../img/media/blog/telemedicine.jpg';
// import ImgCovid19 from '../../../../img/media/blog/covid19.jpg';

import ImgBriefing from '../../../../img/media/pressrelease/briefingwire.jpg';
import ImgNewsWire from '../../../../img/media/pressrelease/Newswire-logo.jpg';
import ImgCision from '../../../../img/media/pressrelease/cision.png';

const template = () => {
  return (
    <>
      
      <Grid container columnSpacing={4} rowSpacing={8}>
      <PressRelease
        img={ImgNewsWire}
        link="https://www.newswire.com/news/cliniops-awarded-patent-for-an-innovative-offline-online-esource-or-22307884"
        title= "CliniOps Awarded Patent for an Innovative Offline/Online eSource or Direct Data Collection (DDC) System, for Global Clinical Trials"
        date="April 25, 2024"
      />
      <PressRelease
        img={ImgNewsWire}
        link="https://www.newswire.com/news/cliniops-awarded-patent-for-pioneering-system-to-collect-location-22039742"
        title= "CliniOps Awarded Patent for Pioneering System to Collect Location Agnostic Clinical and Non-Clinical Data"
        date="May 18, 2023"
      />
      <PressRelease
          img={ImgNewsWire}
          link="https://www.newswire.com/news/cliniops-recognized-in-gartner-hype-cycles-for-fifth-straight-year-21854369"
          title= "CliniOps Recognized in '𝐆𝐚𝐫𝐭𝐧𝐞𝐫 𝐇𝐲𝐩𝐞 𝐂𝐲𝐜𝐥𝐞' for Fifth straight year!"
          date="October 26, 2022"
        />
      <PressRelease
          img={ImgNewsWire}
          link="https://www.newswire.com/news/cliniops-announces-strategic-partnership-with-my-total-health-21853614"
          title= "CliniOps Announces Strategic Partnership with My Total Health"
          date="October 20, 2022"
        />
        <PressRelease
          img={ImgNewsWire}
          link="https://www.newswire.com/news/cliniops-connects-with-exostar-to-deliver-a-unified-access-experience-21823556"
          title="CliniOps Connects With Exostar to Deliver a Unified Access Experience for Digital Clinical Trials"
          date="September 15, 2022"
        />
        <PressRelease
          img={ImgCision}
          link="https://www.prweb.com/releases/2017/06/prweb14414347.htm"
          title="CliniOps, Inc. Receives the ‘2017 Frost & Sullivan Technology Leadership Award for Digitalization of Clinical Trials’"
          date="June 11, 2017"
        />
        <PressRelease
          img={ImgCision}
          link=" https://www.prweb.com/releases/cliniops_inc/esource_solution/prweb11860180.htm"
          title='CliniOps, Inc. Named in the ‘2014 Global Hot Technology Start-Up Watch List’ for the TiE50 Awards Program'
          date="May 16, 2014"
        />
        <PressRelease
          img={ImgBriefing}
          link="  https://www.briefingwire.com/pr/cliniopss-yerramalli-subramaniam-recognized-as-computerworlds-premier-100-it-leaders-for-2015"
          title="CliniOps's Yerramalli Subramaniam recognized as Computerworld's Premier 100 IT Leaders for 2015"
          date="November 05, 2014"
        />
        
      </Grid>
    </>
  );
}

export default template;