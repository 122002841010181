import React from 'react';
import { Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const template = () => {
  return (
    <>
      <Typography variant="h1" component="h1">
      Partnership with Crila Health: Clinical Trial on Prostate Health, conducted across multiple sites in Mexico
      </Typography>
      <Typography variant="body2" color={blueGrey[400]}>
      <a href="https://crilahealth.com/" target={'_blank'} class="linkcolor">Crila Health</a>
      </Typography>
    </>
  );
}

export default template;