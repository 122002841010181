import React from 'react';
import { Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const template = () => {
  return (
    <>
      <Typography variant="h1" component="h1">
      A cluster randomized trial to improve Maternal-Child Outcomes for HIV-positive pregnant women in Lesotho, Africa (IMPROVE), using Disruptive Technologies 
      </Typography>
      <Typography variant="body2" color={blueGrey[400]}>
      <a href=" https://pedaids.org/" target={'_blank'} class="linkcolor">Elizabeth Glaser Pediatric AIDS Foundation</a>
      </Typography>
    </>
  );
}

export default template;