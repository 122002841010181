import React from "react";
import { Grid, Box, Typography, Stack,Alert, Link } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";

import { PageHero, PageContent } from "../../component/page";
import ImgAlben from "../../../img/media/customer-story/_1/AlbenSigamani.png";
import ImgEndo from "../../../img/media/customer-story/_1/Endocrinology-Diabetes-and-Metabolism-Journal.png";
import ImgCIMED from "../../../img/media/customer-story/_1/CIMED-logo.png";
import ImgMSMCNH from "../../../img/media/customer-story/_1/MSMC-NH-transB.png";
import ImgKRMH from "../../../img/media/customer-story/_1/KRMHospital_TransB.png";
import ImgNH from '../../../img/media/customer-story/_1/Narayana-Hrudayalaya-Limited-New-Logo4.png';





// Page Imports
import _0 from "./customer-story_14/_0";
import { Block } from "@mui/icons-material";

const template = () => {
  return (
    <>
      <PageHero color={teal}>
        <_0 />
      </PageHero>
      <PageContent>
        <Grid item>
          <Typography paragraph>
          When hospitals or clinics are selected as sites for clinical trials, it means the sites have gone 
          through a rigorous eligibility process based on proven capabilities to start and complete trials 
          with credibility. Aside from a strong experienced site team, technology is also applied to fulfill 
          operational requirements and to ensure reliable and accurate data collection. A crucial aspect of 
          data collection is source documentation.
          </Typography>
          <Typography paragraph>
          Before, during and after clinical trial, the participant’s files from electronic health records, 
          laboratory results, medication adherence tracking, among others form source documentation. Every 
          activity of the participant per protocol is documented and is the main reference of the investigators 
          during the trial. The rights and safety of the participant are protected with accurate documentation. 
          </Typography>

          <Typography paragraph>
          For three interventional, randomized and placebo-controlled studies in 2016 sponsored by CIMED India, 
          CliniOps collaborated as technology partner to support two sites:  Mazumdar Shaw Medical Centre of Narayana 
          Health (NH) and KRM Hospital and Research Centre (KRM), Lucknow.  The general study details include the following:
          </Typography>

          <Typography paragraph>
            <ol start={1}>
                <li>
                    Study Title: A poly herbal for control of blood sugar in subjects with impaired glucose tolerance and or early type 2 diabetes.
                </li>
                    <ul>
                    <li>
                        123 Participants: Each participant took 2 capsules twice daily for 6 months.
                    </li>
                    </ul>
                <li>
                    Study Title: Antiviral effect of 3 herbs in chronic inactive carriers of hepatitis B virus.
                </li>
                    <ul>
                    <li>
                        23 Participants: Each participant took 3 capsules twice daily for 12 months.
                    </li>
                    </ul>
                <li>
                    Study Title: Role of Breathe Free in the management of bronchial asthma.
                </li>
                    <ul>
                    <li>
                        39 Participants: Each participant took 2 capsules twice per day for 6 months.
                    </li>
                    </ul>

            </ol>
         </Typography>
        
        <Typography paragraph>
            With studies proceeding in real time in both NH and KRM sites starting within the same year, 
            it was important for CliniOps to deploy its mobile, and cloud-based eSource solution for efficient 
            data collection. Throughout the 3 trials, CliniOps supported reliable source documentation that was 
            also protective of the participant’s safety and privacy in both NH and KRM sites.
        </Typography>
          

          <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Stack
              spacing={4}
              sx={{ pl: 4 }}
              direction={{ xs: "column", md: "row" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "220px",
                  width: "220px",
                  height: "250px",
                  borderRadius: 2,
                  backgroundImage: `url(${ImgAlben})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                }}
              />
              <Box>
            <Typography paragraph>
              <em>
              “CliniOps App is a really cool application to make source documentation in any hospital, 
              for research or clinical trials, really easy and user friendly. We are using them on a 
              few trials at NH and find it  quite interesting .” 
              </em>
            </Typography>
            <Typography variant="overline" paragraph>
              <strong>
              Dr. Alben Sigamani
              <br></br>
              Principal Investigator, Group Head Clinical Research, Narayana Health
              </strong>
            </Typography>
          </Box>
            </Stack>
          </blockquote>
        
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${ImgEndo})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box>
            <Typography paragraph>
            The results of the clinical study, <Link href="https://researchopenworld.com/a-polyherbal-indian-system-of-medicine-ayush-preparation-for-optimization-of-glycemic-control-in-newly-diagnosed-type-2-diabetes-and-prediabetes-a-multicenter-randomised-double-blind-placebo-co/" target={"_blank"} underline="hover">
            “A Polyherbal Indian System of Medicine (Ayush) Preparation for Optimization 
            of Glycemic Control in Newly Diagnosed Type 2 Diabetes and Prediabetes; A Multicenter, Randomised, Double-Blind, 
            Placebo- Controlled Trial”</Link>, and CliniOps involvement therein was published in the Endocrinology, Diabetes and 
            Metabolism Journal (ISSN: 2771-8018), on February 09, 2019.
            </Typography>
            </Box>
        </Stack>
        </Alert>


        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '10px',
                marginBottom: '10px',
                backgroundImage: `url(${ImgCIMED})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box>
            <Typography paragraph>
            The trial was sponsored by <Link href="https://cimedlife.com/" target={"_blank"} underline="hover">CIMED</Link> (Composite Interceptive Medicine Limited).
            </Typography>
            </Box>
        </Stack>
        </Alert>
        

        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '10px',
                marginBottom: '10px',
                backgroundImage: `url(${ImgNH})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box>
            <Typography paragraph>
            The trial was designed by Department of Clinical Research at <Link href="https://www.narayanahealth.org/" target={"_blank"} underline="hover">Narayana Health</Link>, Bangalore.
            </Typography>
            </Box>
        </Stack>
        </Alert>


        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
          <Stack display={"block"}>
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '10px',
                // marginBottom: '10px',
                backgroundImage: `url(${ImgMSMCNH})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            />
            <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '10px',
                // marginBottom: '10px',
                backgroundImage: `url(${ImgKRMH})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
            /></Stack>
            <Box>
            <Typography paragraph>
            This study was approved by the institute ethics committee at <Link href="https://www.narayanahealth.org/hospitals/bangalore/mazumdar-shaw-medical-center-bommasandra" target={"_blank"} underline="hover">
            Mazumdar Shaw Medical Center
            </Link>, 
            Bangalore (approval number NHH/AEC_RC_2016-078(B) and at KRM Hospital, Lucknow (approval number IEC/02/19/17).
            </Typography>
            </Box>
        </Stack>
        </Alert>

        </Grid>
      </PageContent>
    </>
  );
};

export default template;
