import React from "react";
import { Grid, Box, Typography, Stack,Alert, Link } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";

import { PageHero, PageContent } from "../../component/page";
import ImgLaura from "../../../img/media/customer-story/_1/LauraGuay.jpg";
import Imgplos from "../../../img/media/customer-story/_1/PLOS-ONE_logo_nobug.png";
import Imgpop from "../../../img/media/customer-story/_1/pop-council-logo.png";
import Imgicasa from "../../../img/media/customer-story/_1/icasa.png";
import ImgUSAID from "../../../img/media/customer-story/_1/USAID-Identity.png";
import ImgPEPFAR from "../../../img/media/customer-story/_1/PEPFAR.png";
import Imgegpaf from '../../../img/media/customer-story/_1/EGPAF_Logo.png';

// Page Imports
import _0 from "./customer-story_5/_0";

const template = () => {
  return (
    <>
      <PageHero color={teal}>
        <_0 />
      </PageHero>
      <PageContent>
        <Grid item>
          <Typography variant="h4" paragraph>
          A cluster randomized trial to study the effect of Family-centered Model of HIV Care (FAM-CARE) on Viral Suppression and Retention in Care of HIV-positive Children in Eswatini (formerly Swaziland)</Typography>
          <Typography paragraph>
          In partnership with USAID’s Project SOAR (Supporting Operational Aids Research) and the Swaziland Ministry of Health (MOH), the Elizabeth Glaser Pediatric AIDS Foundation (EGPAF) initiated and completed a randomized trial that evaluated the effect of implementing a family-centered model of care program (FAM-CARE) on viral suppression and care retention in a prospective cohort of HIV-positive children and their caregivers in Hhohhoo region, Eswatini, from 2016 to 2019. 
          </Typography>
          <Typography paragraph>
          FAM-CARE, as new program, focused on achieving a more family-centered care particularly for mother and child during comprehensive pediatric HIV treatment and support. This program is a marked shift from the standard of care where adult and pediatric clinics are separated due to differing expertise required to treat them. With FAM-CARE, response to the health needs of every family member would be addressed in one clinic.  
          </Typography>
          <Typography paragraph>
          In eight healthcare clinical sites, 742 participants were enrolled, of which 379 were HIV-positive children and 363 were caregivers. The participants were recruited and every three months within a year,  were interviewed on their FAM-CARE experience. The data collected were assessed in terms of FAM-CARE’s feasibility, acceptability and effectiveness.
          </Typography>

          <Typography paragraph>
          CliniOps provided e-Source and EDC, customized with complete offline capability in case of unstable connectivity. This capability enabled site coordinators to interact and engage with participants during visits even without WIFI connectivity particularly in remote areas. As soon as connectivity is available, data is automatically plugged in and accessed by study team. With CliniOps’ solutions, participants’ records were fully digitized as eCRF with use of smart tablets to effectively cover data from informed consent process to year-long monitoring.  
          </Typography>

          <Typography paragraph>
          Considering that study results were destined to be part of critical data about FAMCARE’s feasibility as national and even global policy, the use of CliniOps’ technology applications for reliable data collection and analysis is significant undertaking and contribution.    
          </Typography>

        
          <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Stack
              spacing={4}
              sx={{ pl: 4 }}
              direction={{ xs: "column", md: "row" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "220px",
                  width: "220px",
                  height: "220px",
                  borderRadius: 2,
                  backgroundImage: `url(${ImgLaura})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                }}
              />
              <Box>
            <Typography paragraph>
              <em>
                "I have to say that now personally having gone through each and every eCRF form on the tablet, I really appreciate what a great system this is and how it will help us to have better data in the long run."</em>
            </Typography>
            <Typography variant="overline" paragraph>
              <strong>
                Dr. Laura Guay
                <br />
                Vice President for Research, Elizabeth Glaser Pediatric AIDS Foundation
              </strong>
            </Typography>
          </Box>
            </Stack>
          </blockquote>
         
        <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
        <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
        <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                backgroundImage: `url(${Imgplos})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
          
           <Box>
           <Typography paragraph>
           A publication titled, <Link href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0256256" target={"_blank"} underline="hover">"A randomized controlled trial evaluating the effects of a family-centered HIV care model on viral suppression and retention in care of HIV-positive children in Eswatini”</Link>, and CliniOps’s involvement therein, was published by the PLOS ONE, on August 24, 2021</Typography>
           </Box>
          </Stack>
     </Alert>
     <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
     <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
     <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2,
                marginTop: '30px',
                marginBottom: '30px'
                /*backgroundImage: `url(${Imgegpaf})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top center'*/
            }}          
          ><Typography><b>ELIZABETH GLASER PEDIATRIC AIDS FOUNDATION</b></Typography></Box>
           <Box>
           <Typography paragraph>
           A report on <Link href="https://www.pedaids.org/research/effect-family-centered-model-hiv-care-fam-care-viral-suppression-retention-care-hiv-positive-children-swaziland/" target={"_blank"} underline="hover">‘Effect of Family-centered Model of HIV Care (FAM-CARE) on Viral Suppression and Retention in Care of HIV-positive Children in Swaziland’</Link>, was published by Pediatric Aids Foundation
           </Typography>
           </Box>
      </Stack>
     </Alert>
     <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
     <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
     <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '55px',
                borderRadius: 2
                /*backgroundImage: `url(${Imgpop})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'*/
            }}
          ><Typography paragraph><b>POPULATION COUNCIL</b></Typography>
          </Box>
           <Box>
           <Typography paragraph>
           A publication titled, <Link href="https://www.pedaids.org/wp-content/uploads/2018/03/20170323_SwaziFamCare_Brief_DRAFT.pdf" target={"_blank"} underline="hover">‘Effect of a Family-centered Model of HIV Care on Viral Suppression and Care Retention Among HIV-positive Children in Swaziland’</Link> was published by the Population Council, on January 1, 2021.</Typography>
           </Box>
      </Stack>
     </Alert>
     <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
     <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
     <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '120px',
                borderRadius: 2,
                backgroundImage: `url(${Imgicasa})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          />
           <Box>
           <Typography paragraph>
           Findings presented at the 20th <Link href="http://www.icasa2019rwanda.org/" target={"_blank"} underline="hover">International Conference on AIDS and STIs in Africa</Link>, December 2019:
           </Typography>
           <Typography>
           -	Association of ART Regimen with Viral Suppression in HIV-positive Children in Eswatini: Baseline Data from the FAM-CARE Study”
           </Typography>
           <Typography>
            -	There Are No Secrets Any More”: Experiences of Caregivers and Health Care Workers Using the Family-centered Care Model in Eswatini”
           </Typography>
          </Box>
      </Stack>
     </Alert>
     <Alert icon={false} severity="info" sx={{ marginTop: 4}}>
     <Stack spacing={4} direction={{xs: "column", md: "row"}} sx={{ pl: 4 }} alignItems="center">
      <Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '175px',
                width: '175px',
                height: '80px',
                borderRadius: 2
                /*backgroundImage: `url(${ImgPEPFAR})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'*/
            }}
          ><Typography paragraph><b>PEPFAR, USAID</b></Typography>
          </Box>
          {/*<Box
              xs="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '114px',
                width: '114px',
                height: '80px',
                borderRadius: 2,
                marginTop:'30px !important',
                backgroundImage: `url(${ImgUSAID})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center'
            }}
          ><Typography fontSize={"12px"}><b>USAID</b></Typography>
          </Box>*/}
           <Box>
           <Typography paragraph>
           The study was co-sponsored by the U.S. President's Emergency Plan for AIDS Relief <Link href="https://www.state.gov/pepfar/" target={"_blank"} underline="hover">(PEPFAR)</Link>, and The U.S. Agency for International Development <Link href="https://www.usaid.gov/" target={"_blank"} underline="hover">(USAID)</Link> 
           </Typography>
          
          </Box>
      </Stack>
     </Alert>

        {/* </Alert> */}

          {/* <blockquote
            style={{ borderLeft: `4px solid ${blueGrey[100]}`, marginLeft: -1 }}
          >
            <Box sx={{ pl: 4 }}>
              <Typography paragraph>
                A Case Study titled ‘Use of Decentralized Clinical Technologies
                in Lower Urinary Tract Symptoms’ is published in the Journal of
                Medicine, University of Santo Tomas, in the October 2022 issue,
                authored by Dr. Nadina Jose & Avik Pal
              </Typography>
            </Box>
          </blockquote> */}
        </Grid>
      </PageContent>
    </>
  );
};

export default template;
