import React from 'react';
import { Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const template = () => {
  return (
    <>
      <Typography variant="h1" component="h1">
      Electronic Data Capture for NKF Initiative 'Path to Wellness': Serving Population At-Risk of Kidney Disease
      </Typography>
      <Typography variant="body2" color={blueGrey[400]}>
      <a href="https://azkidney.org/" target={'_blank'} class="linkcolor">National Kidney Foundation of Arizona</a>
      </Typography>
    </>
  );
}

export default template;