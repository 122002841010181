import React from 'react';
import { Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const template = () => {
  return (
    <>
      <Typography variant="h1" component="h1">
      Onchocerciasis Study in Ghana: Deploying Mobile Technologies for High Quality Data Capture in Remote Sites 
      </Typography>
      <Typography variant="body2" color={blueGrey[400]}>
      <a href="https://medicine.wustl.edu/" target={'_blank'} class="linkcolor">Washington University School of Medicine</a>
      </Typography>
    </>
  );
}

export default template;