import React from 'react';
import { Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const template = () => {
  return (
    <>
      <Typography variant="h1" component="h1">
      Clinical performance of a smartphone-enabled home based urine testing platform compared to lab based standard of care testing
      </Typography>
      <Typography variant="body2" color={blueGrey[400]}>
      <a href="https://healthy.io/" target={'_blank'} class="linkcolor">inui Health (formerly Scanadu)</a>
      </Typography>
    </>
  );
}

export default template;