import React from 'react';
import { Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const template = () => {
  return (
    <>
      <Typography variant="h1" component="h1">
      Stepping in for Data Management and Analysis: Study on Catheter-related blood stream infections (CRBSI), for post-marketing experience with Neutrolin® at 20 dialysis units across Germany
      </Typography>
      <a href="https://cormedix.com/" target={'_blank'} class="linkcolor">
      CorMedix Inc.
      </a>
    </>
  );
}

export default template;