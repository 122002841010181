import React from 'react';
import { Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const template = () => {
  return (
    <>
      <Typography variant="h1" component="h1">
      Lymphatic Filariasis study in Papua New Guinea, Indonesia, India, Haiti, and Fiji: A Clinical trial with 26,836 participants in LMIC countries, with offline/online capture using mobile devices      </Typography>
      <a href="https://medicine.wustl.edu/" target={'_blank'} class="linkcolor">
      Washington University School of Medicine
      </a>
    </>
  );
}

export default template;