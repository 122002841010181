import React from 'react';
import { Grid } from '@mui/material';

import RouterList, { Website } from '../../../router';
import BlogPost from '../../../component/blog-post';

import ImgVektorMedical from '../../../../img/media/customer-story/_1/vektor-medical-logo.jpg';
import ImgCrilaHealth from '../../../../img/media/customer-story/_1/crila_Health.png';
import ImgMDGH from '../../../../img/media/customer-story/_1/MDGH.svg'
import Imgegpaf from '../../../../img/media/customer-story/_1/EGPAF_Logo.png';
import Imgscanadu from '../../../../img/media/customer-story/_1/scanadu.jpg';
import Imgcpmc from "../../../../img/media/customer-story/_1/CPMC-Logo.png";
import Imgsanaria from "../../../../img/media/customer-story/_1/sanaria-logo-small.png";
import ImgWashU from '../../../../img/media/customer-story/_1/WashU-logo.png';
import ImgRenibus from '../../../../img/media/customer-story/_1/Renibus_Logo.jpg';
import ImgNKF from '../../../../img/media/customer-story/_1/national-kidney-foundation-inc-logo-vector.png';
import ImgNH from '../../../../img/media/customer-story/_1/Narayana-Hrudayalaya-Limited-New-Logo4.jpg';
import CorMedix from '../../../../img/media/customer-story/_1/CorMedix.png';
import BioconBiologics from '../../../../img/media/customer-story/_1/BioconBiologics.png';


const template = () => {
  return (
    <>
      <Grid container columnSpacing={4} rowSpacing={8}>
      <BlogPost
          img={BioconBiologics}
          link={RouterList.media.customerstory_18}
          title="Patient Centricity through Telemedicine Amidst Pandemic: A Clinical Trial for Treatment of COVID-19 in India, across 20+ sites, supported in 10+ languages"
          subTitle="Biocon Biologics"
        />
      <BlogPost
          img={ImgCrilaHealth}
          link={RouterList.media.customerstory_3}
          title="Partnership with Crila Health: Clinical Trial on Prostate Health, conducted across multiple sites in Mexico"
          subTitle="Crila Health"
        />
        <BlogPost
          img={ImgVektorMedical}
          link={RouterList.media.customerstory_1}
          title="Configurable Platform Speeds Development of Complex Workflows in Ground-Breaking Clinical Study on cardiac arrhythmia"
          subTitle="Vektor Medical, Inc."
        />
        <BlogPost
          img={ImgCrilaHealth}
          link={RouterList.media.customerstory_2}
          title="DCT in Action: Menopause Study Started and Completed During Pandemic, conducted across multiple sites in Mexico"
          subTitle="Crila Health"
        />
      <BlogPost
          img={ImgMDGH}
          link={RouterList.media.customerstory_4}
          title="Onchocerciasis Study in Democratic Republic of Congo and Côte d'Ivoire: Technology and Determination to Reach Underserved Populations"
          subTitle="Medicines Development for Global Health"
        />
        <BlogPost
          img={Imgegpaf}
          link={RouterList.media.customerstory_16}
          title="Mobile Technologies Assist Intervention Study to Integrate Pediatric TB Services Into Child Healthcare Services (INPUT Study), in Cameroon and Kenya"
          subTitle="Elizabeth Glaser Pediatric AIDS Foundation"
        />
        <BlogPost
          img={ImgWashU}
          link={RouterList.media.customerstory_10}
          title="Onchocerciasis Study in Ghana: Deploying Mobile Technologies for High Quality Data Capture in Remote Sites"
          subTitle="Washington University School of Medicine"
        />
        <BlogPost
          img={Imgsanaria}
          link={RouterList.media.customerstory_9}
          title="Enabling Remote Monitoring in a Malaria study: Regimen Optimization Trial of PfSPZ Vaccine in Equatorial Guinea"
          subTitle="Sanaria"
        />
        <BlogPost
            img={ImgRenibus}
            link={RouterList.media.customerstory_12}
            title="Unified Technology Platform support for Phase 1B dose-escalation study on subjects with chronic kidney disease (CKD)"
            subTitle="Renibus"
          />
          <BlogPost
            img={Imgsanaria}
            link={RouterList.media.customerstory_11}
            title="Off-line Data Collection with sites in Africa: Study to Evaluate Safety, Immunogenicity and Efficacy of PfSPZ Vaccine in HIV Negative and HIV Positive Tanzanian Adults"
            subTitle="Sanaria"
          />
          <BlogPost
              img={CorMedix}
              link={RouterList.media.customerstory_15}
              title="Stepping in for Data Management and Analysis:  Study on Catheter-related blood stream infections (CRBSI), for post-marketing experience with Neutrolin® at 20 dialysis units across Germany"
              subTitle="CorMedix Inc"
            />
          <BlogPost
          img={Imgegpaf}
          link={RouterList.media.customerstory_5}
          title="A cluster randomized trial to study the effect of Family-centered Model of HIV Care (FAM-CARE) on Viral Suppression and Retention in Care of HIV-positive Children in Eswatini (formerly Swaziland)"
          subTitle="Elizabeth Glaser Pediatric AIDS Foundation"
        />
         <BlogPost
         img={ImgNH}
         link={RouterList.media.customerstory_14}
         title="Type 2 Diabetes, Chronic Hepatitis B and Bronchial Asthma studies: One Technology Platform across multiple sites in India"
         subTitle="Narayana Health"
          />
         <BlogPost
         img={ImgWashU}
         link={RouterList.media.customerstory_17}
         title="Lymphatic Filariasis study in Papua New Guinea, Indonesia, India, Haiti, and Fiji: A Clinical trial with 26,836 participants in LMIC countries, with offline/online capture using mobile devices"
         subTitle="Washington University School of Medicine"
          />

          <BlogPost
          img={Imgscanadu}
          link={RouterList.media.customerstory_6}
          title="Clinical performance of a smartphone-enabled home based urine testing platform compared to lab based standard of care testing"
          subTitle="inui Health (formerly Scanadu)"
          />  
        <BlogPost
          img={Imgegpaf}
          link={RouterList.media.customerstory_8}
          title="A cluster randomized trial to improve Maternal-Child Outcomes for HIV-positive pregnant women in Lesotho, Africa (IMPROVE), using Disruptive Technologies"
          subTitle="Elizabeth Glaser Pediatric AIDS Foundation"
        />
          <BlogPost
          img={ImgNKF}
          link={RouterList.media.customerstory_13}
          title="Electronic Data Capture for NKF Initiative ‘Path to Wellness’: Serving Population At-Risk of Kidney Disease"
          subTitle="National Kidney Foundation of Arizona"
        />
        <BlogPost
          img={Imgcpmc}
          link={RouterList.media.customerstory_7}
          title="Moving from Paper to Electronic Consent involving patients with substance abuse"
          subTitle="California Pacific Medical Center Research Institute"
        />
      </Grid>
    </>
  );
}

export default template;