import React from 'react';
import { Typography } from '@mui/material';

const template = () => {
  return (
    <>
      <Typography variant="h1" component="h1">
        Newsroom
      </Typography>
    </>
  );
}

export default template;